import * as React from "react"

function App() {
    return (
    <>

        {/* Page HTML */}

        <section id='content-3D' data-state='loading'>

            <div id="deviceRotated">
                <span>
                    <img src="/modules/custom/co_bavaria_arcade_150/3D/ec_clubpremium/nuestras_cervezas/dist/assets/images/deviceRotated.svg" />
                    Rota tu dispositivo.
                </span>
            </div>

            <div className='Loader'>
                <span className='loader-circle'></span>
                <span className='loadedValue'>0%</span>
            </div>

            <div className='outModal'>
            </div>

            <canvas id='canvas-3D'></canvas>

            <div className='pop-up-int'>
                {/* <span className='close-pop'></span> */}
                <span className='drag-icon'></span>
                <p>Interactúa con <span>la botella</span></p>
            </div>

            <div className='generalUI'>

                <div className='beerInfo'>
                    <div className='mobile-close'></div>

                    <h1 className='beerInfo-title'>club premium<span></span></h1>

                    <div className='beerInfo-data'>
                        
                    </div>
                </div>

                <div className='c-arrow go-back' data-action='back'> <span>Atrás</span> </div>

                <div className='c-arrow c-arrow_int' data-action='prev'></div>
                <div className='c-arrow c-arrow_int' data-action='next'></div>

                <div className='view-back'></div>
                <div className='close-back'></div>

                <div className='info-mobile in'></div>

                <div className='scroll-icon'></div>

            </div>

        </section>

    </>
    )
}

export default App
