import * as THREE from "three";

import Stats from "three/examples/jsm/libs/stats.module"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
// import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module"
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader"
// import { HDRCubeTextureLoader } from "three/examples/jsm/loaders/HDRCubeTextureLoader"

import {
    EffectComposer, RenderPass, EffectPass,
    BlendFunction,
    ToneMappingEffect, ToneMappingMode,
    NoiseEffect,
    DepthOfFieldEffect, 
    BloomEffect, KernelSize,
    // SMAAImageLoader, SMAAEffect, SMAAPreset, EdgeDetectionMode,
} from "postprocessing"

import { gsap } from 'gsap'
// import { ScrollTrigger } from "gsap/all";

//

import { values } from '../config/values'
import * as mat from "../config/materials"


// ---------------------------------------------------------------------------

const isM = (function isMobile() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}())

const devRot = document.querySelector('#deviceRotated')
isM ? devRot.classList.add('mobile') : devRot.remove()

const size = {
    w: 0, h: 0, halfW: 0, halfH: 0
}

//

let origin = location.pathname.split('/')[2]
let settings = {}

switch (origin) {
    case 'club-clasica':
        settings.ref = 'clasica'
        settings.nameString = 'Clásica'

        settings.prev = 'platino'
        settings.next = 'doble-malta'
        break
    case 'club-platino':
        settings.ref = 'platino'
        settings.nameString = 'Platino'

        settings.prev = 'doble-malta'
        settings.next = 'clasica'
        break
    case 'club-doble-malta':
        settings.ref = 'doble'
        settings.nameString = 'Doble Malta'

        settings.prev = 'clasica'
        settings.next = 'platino'
        break

    default:
        console.error('NO origin match')
        break;
}


async function getData(url) {
    const response = await fetch(url)
    const data = await response.json()
    return data
}

const endpointHost = location.host == 'localhost:3030' ? 'https://clubpremium.dp150.xyz' : location.origin
const printData = async () => {
    const endpointData = await getData(`${endpointHost}/api/beers`)

    const data = endpointData.find( x => x.subtitle == settings.nameString )

    let element = `
        <p>${data.content}</p>
    `
    if(data.image) {
        element = element + `
            <img src='${data.image.url}' alt='${data.image.alt}' />
        `
    }
    if(data.twiter) {
        element = element + `
            <span>${data.twiter}</span>
        `
    }

    //

    document.querySelector('.beerInfo-title span').innerHTML = data.subtitle.toLowerCase()
    document.querySelector('.beerInfo-data').innerHTML = element
}
    



// INIT ----------------------------------------------------------------------

let sigla = 'P'
let clock, camera, scene, renderer, composer;

const GLTF_loader = new GLTFLoader()
const Env_loader = new RGBELoader()
//const urlFiles = location.host == 'localhost:3030' ? '../' : ( location.host == 'localhost:3000' ? '/3D/dist/' : `${drupalSettings.path.themeUrl}/experience/public/3D/dist/`)
const urlFiles = '../'

const contenedorHtml = document.querySelector('#content-3D')
const canvas_refe = document.querySelector('#canvas-3D')


// ---------------------------------------------------------------------------

const init = () => {
    
    console.log(`[${sigla}] Scene init`)

    function getRealSize() {
        size.w = window.innerWidth
        size.h = window.innerHeight
        size.halfW = size.w / 2
        size.halfH = size.h / 2
    }
    getRealSize()

    printData()


    // ---------------------------------------------------------------------------

    clock = new THREE.Clock()

    scene = new THREE.Scene()

    camera = new THREE.PerspectiveCamera( 45, size.w/size.h, 0.1, 150 )
    camera.position.copy( values.interna.camera.pos )
    camera.lookAt( new THREE.Vector3().copy( values.interna.camera.target ) )

    renderer = new THREE.WebGLRenderer({
        canvas: canvas_refe,
        // antialias: true,
        // ----Postprocessing
        powerPreference: "high-performance",
        antialias: false,
        stencil: false,
	    depth: false,
        alpha: true,
    })
    renderer.setPixelRatio( Math.min(window.devicePixelRatio, 2) )
    renderer.setSize( size.w, size.h )
    renderer.physicallyCorrectLights = true
    renderer.outputEncoding = THREE.sRGBEncoding
    renderer.setClearColor(0x00ff00, 0)


    // Extras -----
    // scene.add( new THREE.AxesHelper( 1 ) );

    // let stats = new Stats()
    // stats.dom.style.cssText = 'top: 40%; position: fixed; z-index: 999999'
    // document.body.appendChild( stats.dom )

    let controls = false

    if(controls) {
        let orbit = new OrbitControls( camera, renderer.domElement )
        orbit.target.copy( values.camera.target )
        orbit.update()
    } else {
        setInterval( updateCamera, 1000/60 )
    }


    //
    // Load control ----------------------------------------------------------------
    const loadedElements = { }

    let loadPercent = 0
    let loadFinish = false
    const realLoader = () => {

        let loaded = 0
        Object.keys(loadedElements).forEach( (key) => { loaded += loadedElements[key].loaded })
        let total = 0
        // Object.keys(loadedElements).forEach( (key) => { total += loadedElements[key].total })
        total = 395807 + 131908 + 22356

        //

        let elements = Math.round( (loaded / total) * 100)
        if( isNaN(elements) || total < 1024) elements = 0
        // loadPercent = (mat.texturesData.size.percent + elements) / 2
        loadPercent = elements

        document.querySelector('.loadedValue').innerHTML = `${loadPercent.toFixed(0)}%`
        // document.querySelector('.loadedLine').style.width = `${loadPercent.toFixed(0)}%`

        if(loadPercent === 100 && mat.texturesData.managerState == 'Ready') {

            loadFinish = true
            console.log(`[${sigla}] All ready at ${(clock.getElapsedTime()).toFixed(2)}s \r\n      ${( (total + mat.texturesData.size.total) /1024/1024).toFixed(2)}Mb`)

            gsap.to('.Loader', {
                opacity: 0,
                ease: 'power1.inOut',
                duration: 1.5,
                delay: 1,
                onStart() {
                    entrada()
                },
                onComplete() {
                    this.targets()[0].remove()
                }
            })
        }
    }


    //
    // Camera control - Desktop ----------------------------------------------------------

    const lerp = (start, end, amt) => {
        return (1-amt)*start+amt*end
    }

    function updateCamera(e) {
        if( !pointerMoved || !entradaFinish ) return

        const amt = 0.03

        camera.position.x = lerp(camera.position.x, (pointerPosition.x * 0.45), amt)
        camera.position.z = lerp(camera.position.z, (values.interna.camera.pos.z - (Math.abs(pointerPosition.x) * 0.25)), amt)
        camera.position.y = lerp(camera.position.y, (values.interna.camera.pos.y + (pointerPosition.y * 0.35)), amt)

        camera.lookAt( new THREE.Vector3().copy( values.interna.camera.target ) )
    }


    //
    // EnvMap ------------------------------------------------------------------

    const pmremGenerator = new THREE.PMREMGenerator(renderer)
    const envObj = "studio_country_hall_1k"
    Env_loader
        .setDataType(THREE.UnsignedByteType)
        .load(`${urlFiles}/modules/custom/co_bavaria_arcade_150/3D/ec_clubpremium/nuestras_cervezas/dist/assets/glb/envMap/${envObj}.hdr`,
            (env) => {
                pmremGenerator.compileEquirectangularShader()
                const HDRImap = pmremGenerator.fromEquirectangular(env).texture

                scene.environment = HDRImap
                // scene.background = HDRImap

                HDRImap.dispose()
                pmremGenerator.dispose()
            },
            (xhr) => {
                loadedElements.env = { loaded: xhr.loaded, total: xhr.total }

                // if (xhr.loaded === xhr.total) {
                //     console.log(`[${sigla}] "${envObj}" - ${(xhr.total / 1024 / 1024).toFixed(2)}Mb`)
                // }
            },
            (error) => {
                console.error(`[${sigla}] "${envObj}" Error loading`)
                // console.warn( error )
            }
        )


    const shadow_geo = new THREE.PlaneBufferGeometry(1, 1)
    const shadow_mesh = new THREE.Mesh(shadow_geo, mat.shadow_mat);
    shadow_mesh.name = 'Shadow_mesh_'
    shadow_mesh.position.y = 0.01 // so we're above the ground slightly
    shadow_mesh.rotation.x = - Math.PI / 2;

    // GLB
    const glb01 = 'Botella.glb'
    let bottle_model = { }
    GLTF_loader
        // .setMeshoptDecoder(MeshoptDecoder)
        .load( `${urlFiles}/modules/custom/co_bavaria_arcade_150/3D/ec_clubpremium/nuestras_cervezas/dist/assets/glb/${glb01}`,
            (gltf) => {
                const model = gltf.scene.children[0]

                let cont = bottle_model.base = new THREE.Object3D()
                cont.name = `Base_${settings.ref}`
                // cont.position.x = 0
                // cont.rotation.y = values.interna.back.start.rotY

                cont.add( shadow_mesh.clone() )

                let elem = bottle_model.bottle = model
                elem.name = `Botella_${settings.ref}_grp`
                elem.userData.name = settings.ref

                // Set up
                elem.position.y = values.interna.y + values.interna.entrada.pos.y
                
                elem.rotation.x = values.interna.entrada.rot.x
                elem.rotation.y = values.interna.entrada.rot.y

                // Materials
                elem.traverse( (c) => {
                    if( c.type == 'Mesh' ) {
                        switch (c.name) {
                            case 'Botella_01':
                                c.material = mat.botella_mat
                                elem.renderOrder = 1                                
                                break;
                            case 'Etiqueta_01':
                            case 'Etiqueta_02':
                                c.material = mat[`${settings.ref}_front_mat`]
                                c.renderOrder = 2
                                break;
                            case 'EtiquetaBack':
                                c.material = mat[`${settings.ref}_back_mat`]
                                break;
                            case 'Tapa':
                                c.material = mat[`${settings.ref}_tapa_mat`]
                                c.renderOrder = 2
                                break;
                        
                            default:
                                console.error(`Falta- ${c.name}`)
                                break;
                        }
                    }
                })

                cont.add(elem)
                scene.add(cont)

                raycastElems.push(bottle_model.bottle)
            },
            (xhr) => {
                loadedElements.glb_botella = { loaded: xhr.loaded, total: xhr.total }

                // if (xhr.loaded === xhr.total) {
                //     console.log(`[${sigla}] "${glb01}" - ${(xhr.total / 1024 / 1024).toFixed(2)}Mb`)
                // }
            },
            (error) => {
                console.warn(`[${sigla}] "${glb01}" Error loading`)
                // console.warn( error )
            }
        )

    const glb02 = 'Scenography.glb'
    GLTF_loader
        // .setMeshoptDecoder(MeshoptDecoder)
        .load( `${urlFiles}/modules/custom/co_bavaria_arcade_150/3D/ec_clubpremium/nuestras_cervezas/dist/assets/glb/${glb02}`,
            (gltf) => {
                const model = gltf.scene.children[0]

                model.position.z = -3
                model.rotation.y = Math.PI
                let sf = 7
                model.scale.set(sf*0.65, sf, sf)

                model.material = mat[`scene_${settings.ref}_mat`]

                //
                scene.add( model )

            },
            (xhr) => {
                loadedElements.glb_scenography = { loaded: xhr.loaded, total: xhr.total }

                // if (xhr.loaded === xhr.total) {
                //     console.log(`[${sigla}] "${glb02}" - ${(xhr.total / 1024 / 1024).toFixed(2)}Mb`)
                // }
            },
            (error) => {
                console.warn(`[${sigla}] "${glb02}" Error loading`)
                // console.warn( error )
            }
        )

    
    let entradaFinish = false
    function entrada() {

        const bottle = bottle_model.bottle 
        const base = bottle_model.base

        const t = gsap.timeline({
            defaults: {
                ease: 'power2.out',
                duration: 3.5
            },
            onComplete() {
                // entradaFinish = true
            }
        })

        t.to(bottle.position, {
            y: values.interna.y,
            ease: 'back.out(1.4)'
        }, 0)
        t.to(bottle.rotation, {
            x: 0,
            y: values.interna.back.start.rotY,
            z: 0,
        }, 0)

        /*if(!isM) {
            t.to(base.position, {
                x: values.interna.back.start.posX,
                ease: 'power1.inOut',
                duration: 2.5
            }, 1.5)
            t.from('.beerInfo', {
                x: '-=300',
                ease: 'power1.inOut',
                duration: 2.5
            }, 1.5)
        }

        t.to('.generalUI', {
            opacity: 1,
            ease: 'power2.inOut',
            duration: 3
        }, 2)*/

        t.to('.pop-up-int', {
            opacity: 1,
            y: '-=15',
            duration: 2
        }, 3)
    }

    function popUpOut() {

        const t = gsap.timeline({
            defaults: {
                ease: 'power2.out',
            },
            onStart() {
                entradaFinish = true
            }
        })

        t.to('.pop-up-int', {
            opacity: 0,
            ease: 'power1.in',
            duration: 0.75,
            onComplete() {
                this.targets()[0].remove()
            }
        }, 0)

        if(!isM) {
            const base = bottle_model.base
            t.to(base.position, {
                x: values.interna.back.start.posX,
                ease: 'power1.inOut',
                duration: 2
            }, 0)
            t.from('.beerInfo', {
                x: '-=300',
                ease: 'power1.inOut',
                duration: 2
            }, 0)
        }

        t.to('.generalUI', {
            opacity: 1,
            ease: 'power2.inOut',
            duration: 2.5
        }, 0.25)
        

    }
    document.querySelector('.pop-up-int').addEventListener('pointerup', popUpOut, false)

    //
    //

    function changeRef(e) {
        let bottle = bottle_model.bottle

        let action = e.target.getAttribute('data-action')
        let target = action == 'prev' ? `/club-${settings.prev}` : ( action == 'next' ? `/club-${settings.next}` : '')

        const t = gsap.timeline({
            defaults: { },
            onStart() {
                entradaFinish = false
            },
            onComplete() {
                window.location = `/nuestras-cervezas${target}`
            }
        })

        t.to(bottle.position, {
            y: '+=0.75',
            duration: 1.5,
            ease: 'power1.in',
        }, 0)

        t.to('.outModal', {
            opacity: 1,
            duration: 1,
            onStart() {
                this.targets()[0].style.display = 'block'
            }
        }, 0.75)
    }

    document.querySelectorAll('.c-arrow').forEach( (c) => { c.addEventListener('pointerup', changeRef, false) })

        //

    const viewBackElem = document.querySelector('.view-back')
    const closeBackElem = document.querySelector('.close-back')

    let openBackState = false

    function viewBack() {
        if(!entradaFinish) return

        openBackState = true

        infoMobileElem.classList.remove('in')

        const base = bottle_model.base
        const bottle = bottle_model.bottle

        const t = gsap.timeline({
            defaults: {
                duration: 1,
                ease: 'power2.inOut'
            }
        })

        t.to('.view-back', {
            opacity: 0,
            duration: 0.5,
            onComplete() {
                this.targets()[0].style.display = 'none'
            }
        }, 0)
        t.to('.close-back', {
            opacity: 1,
            duration: 0.5,
            onStart() {
                this.targets()[0].style.display = 'block'
            }
        }, 0.75)

        t.to('.beerInfo', {
            opacity: 0,
            x: '-=25',
            duration: 0.5,
        }, 0)

        //

        t.to(base.position, {
            x: values.interna.back.open.posX,
            z: values.interna.back.open.posZ
        }, 0)

        t.to(bottle.position, {
            y: values.interna.back.open.posY,
        }, 0)
        t.to(bottle.rotation, {
            x: values.interna.back.open.rotX,
            y: values.interna.back.open.rotY,
            z: values.interna.back.open.rotZ,
        }, 0)
    }
    function closeBack() {
        infoMobileElem.classList.add('in')

        openBackState = false

        const base = bottle_model.base
        const bottle = bottle_model.bottle

        const t = gsap.timeline({
            defaults: {
                duration: 1,
                ease: 'power2.inOut'
            }
        })

        t.to('.close-back', {
            opacity: 0,
            duration: 0.5,
            onComplete() {
                this.targets()[0].style.display = 'none'
            }
        }, 0)
        t.to('.view-back', {
            opacity: 1,
            duration: 0.5,
            onStart() {
                this.targets()[0].style.display = 'block'
            }
        }, 0.75)

        t.to('.beerInfo', {
            opacity: 1,
            x: 0,
            duration: 0.5,
        }, 0.5)

        //

        t.to(base.position, {
            x: values.interna.back.start.posX,
            z: values.interna.back.start.posZ
        }, 0)

        t.to(bottle.position, {
            y: values.interna.back.start.posY,
        }, 0)
        t.to(bottle.rotation, {
            x: values.interna.back.start.rotX,
            y: values.interna.back.start.rotY,
            z: values.interna.back.start.rotZ,
        }, 0)
    }

    viewBackElem.addEventListener('pointerup', viewBack, false)
    closeBackElem.addEventListener('pointerup', closeBack, false)

    //

    const infoMobileElem = document.querySelector('.info-mobile')
    const closeInfoMobileElem = document.querySelector('.mobile-close')

    function openInfo() {
        document.querySelector('.beerInfo').classList.add('in')
    }
    function closeInfo() {
        document.querySelector('.beerInfo').classList.remove('in')
    }

    infoMobileElem.addEventListener('pointerup', openInfo, false)
    closeInfoMobileElem.addEventListener('pointerup', closeInfo, false)

        //

    let oldX, directionX, deltaX
    let oldY, directionY, deltaY
    function rotateBotella(e) {
        e.preventDefault()
        contenedorHtml.style.cursor = 'grabbing'

        // Direction
        directionX = pointerPosition.x < oldX ? -1 : 1
        directionY = pointerPosition.y < oldY ? 1 : -1

        deltaX = Math.abs( pointerPosition.x - oldX )
        deltaY = Math.abs( pointerPosition.y - oldY )
        // deltaX = Math.abs( pointerPosition.x - pointerClickPosition.x )
        // deltaY = Math.abs( pointerPosition.y - pointerClickPosition.y )

        oldX = pointerPosition.x
        oldY = pointerPosition.y

        //

        const velocity = !openBackState ? (!isM ? 8 : 6) : (!isM ? 4 : 3)
        const factorY = !isM ? 0.33 : 0.65

        bottle_model.bottle.rotation.y += (deltaX * velocity ) * directionX
        bottle_model.bottle.rotation.x += (deltaY * velocity * factorY) * directionY
        bottle_model.bottle.rotation.z += (deltaX * velocity * 0.025) * directionX
    }


    // Raycast ------------------------------------------------------------
    const raycast = new THREE.Raycaster()
    let raycastData = {
        state: false,
        obj: null,
    }
    let raycastElems = []
    
    function ray(e, source) {
        // e.preventDefault()

        if(source == 'Desktop') {
            raycast.setFromCamera(pointerPosition, camera)
        } else {
            raycast.setFromCamera(pointerClickPosition, camera)
        }

        let intersects = raycast.intersectObjects(raycastElems, true)

        if( intersects.length > 0 ) {
            const obj = intersects[0].object.parent
            
            raycastData.state = true
            raycastData.obj = obj

            contenedorHtml.style.cursor = 'grab'
        } else {
            // raycastData.state = false
            raycastData.obj = null

            contenedorHtml.style.cursor = 'default'
        }
    }

    //
    // Desktop------------------------------------------------------------------

    let pointerMoved = false // For camera movement

    let pointerDown = false
    let pointerDraged = false

    let pointerClickPosition = new THREE.Vector2(size.halfW, 0) // {x: size.halfW, y: 0}
    let pointerPosition = new THREE.Vector2(size.halfW, 0)

    function onPointerDown(e) {
        switch (e.pointerType) {
            case 'mouse':
            case 'pen':
                pointerDown = true
                raycastData.state = false

                let rect = renderer.domElement.getBoundingClientRect()
                pointerClickPosition.x = ( ( e.clientX - rect.left ) / rect.width ) * 2 - 1
                pointerClickPosition.y = - ( ( e.clientY - rect.top ) / rect.height ) * 2 + 1

                oldX = pointerPosition.x
                oldY = pointerPosition.y
                break;
        }
    }
    function onPointerMovement(e) {
        switch (e.pointerType) {
            case 'mouse':
            case 'pen':
                pointerMoved = true

                let rect = renderer.domElement.getBoundingClientRect()
                pointerPosition.x = ( ( e.clientX - rect.left ) / rect.width ) * 2 - 1
                pointerPosition.y = - ( ( e.clientY - rect.top ) / rect.height ) * 2 + 1

                if(entradaFinish) ray(e, 'Desktop')

                // Manipulacion botella
                if( pointerDown ) {
                    const tolerance = 0.025
                    if( Math.abs( (pointerClickPosition.x - pointerPosition.x) ) > tolerance || Math.abs( (pointerClickPosition.y - pointerPosition.y) ) > tolerance ) {
                        pointerDraged = true
                        if(raycastData.state && entradaFinish) rotateBotella(e)
                    }
                }
                break;
        }
    }
    function onPointerUp(e) {
        switch (e.pointerType) {
            case 'mouse':
            case 'pen':
                pointerDown = false
                pointerDraged = false
                break;
        }
    }

    contenedorHtml.addEventListener( 'pointerdown', onPointerDown, false)
    contenedorHtml.addEventListener( 'pointermove', onPointerMovement, false )
    contenedorHtml.addEventListener( 'pointerup', onPointerUp, false )

        //

    function onTouchStart(e) {
        pointerDown = true

        let rect = renderer.domElement.getBoundingClientRect()
        pointerClickPosition.x = ( ( e.touches[ 0 ].clientX - rect.left ) / rect.width ) * 2 - 1
        pointerClickPosition.y = - ( ( e.touches[ 0 ].clientY - rect.top ) / rect.height ) * 2 + 1

        if(entradaFinish) ray(e, 'Mobile')

        oldX = pointerClickPosition.x
        oldY = pointerClickPosition.y
    }
    function onTouchMove(e) {
        let rect = renderer.domElement.getBoundingClientRect()
        pointerPosition.x = ( ( e.touches[ 0 ].clientX - rect.left ) / rect.width ) * 2 - 1
        pointerPosition.y = - ( ( e.touches[ 0 ].clientY - rect.top ) / rect.height ) * 2 + 1

        // Manipulacion botella
        if( pointerDown ) {
            pointerDraged = true
            if(raycastData.state && entradaFinish) rotateBotella(e)
        }
    }
    function onTouchEnd(e) {
        pointerDown = false
        pointerDraged = false

        raycastData.state = false
        raycastData.obj = null
    }

    contenedorHtml.addEventListener( 'touchstart', onTouchStart, false)
    contenedorHtml.addEventListener( 'touchmove', onTouchMove, false)
    contenedorHtml.addEventListener( 'touchend', onTouchEnd, false)


    // Render ------------------------------------------------------------------

    const renderPass = new RenderPass(scene, camera)

    const noiseEffect = new NoiseEffect({ premultiply: true })
    noiseEffect.blendMode.opacity.value = 0.5

    const toneMappingEffect = new ToneMappingEffect({
        mode: ToneMappingMode.ACES_FILMIC,
        resolution: 128
    })

    const bloomEffect = new BloomEffect({
        intensity: 0.15,
        luminanceThreshold: 0.25,
        luminanceSmoothing: 0.7,
        kernelSize: KernelSize.HUGE
    })

    const dofEffect = new DepthOfFieldEffect( camera, {
        focusDistance: 0.15,
        focalLength: 0.45,
        bokehScale: 4
    })

    let ePass
    if(!isM) {
        ePass = new EffectPass( 
            camera,
            bloomEffect,
            toneMappingEffect,
        )
    } else {
        ePass = new EffectPass( 
            camera,
            toneMappingEffect,
        )
    }

    composer = new EffectComposer(renderer, {
        frameBufferType: THREE.HalfFloatType
    })
    composer.addPass( renderPass )
    composer.addPass( ePass )

    // ---------------------------------------------------------------------------

    function onWindowResize() {
        if(!isM) getRealSize()
        
        camera.aspect = size.w / size.h
        camera.updateProjectionMatrix()
        renderer.setSize( size.w, size.h )
        composer.setSize( size.w, size.h )
    }
    window.addEventListener( 'resize', onWindowResize, false)


    // ---------------------------------------------------------------------------

    function animate() {
        requestAnimationFrame( animate )
        const velocity = !isM ? 10 : 6

        // stats.update()

        if(!loadFinish) realLoader()

        //

        composer.render( clock.getDelta() )
        // renderer.render( scene, camera )
    }
    animate()
}
export { init }