import { Vector3, Euler } from 'three'

const isM = (function isMobile() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}() )

const w = window.innerWidth
const h = window.innerHeight
const p = w/h

// ------

let bottlesY = !isM ? 1.35 : 1.3

export let values = {
    names: {
        clasica: 'clásica',
        platino: 'platino',
        doble: 'doble malta'
    },
    
    home: {
        iPos: {clasica: 0, platino: 2, doble: 1},
        camera: {
            pos: {x: 0, y: 2.15, z: 5.8},
            target: {x: 0, y: 1.95, z: 0},
        },
        entrada: { 
            pos: {x: 0, y: 0.75, z: 0}, // sumados a los finales
            rot: {x: -0.3, y: 0.5, z: 0.15}
        },
        bottles: {
            0: {x: -2.75, y: bottlesY, z: -1.75},
            1: {x: 0, y: bottlesY, z: 0.65},
            2: {x: 2.75, y: bottlesY, z: -1.75},
        },
        namesY: !isM ? 3.55 : 3.45
    },
    
    interna: {
        y: bottlesY,

        camera: {
            pos: {x: 0, y: 1.8, z: 4.5},
            target: {x: 0, y: 1.65, z: 0},
        },
        entrada: { 
            pos: {x: 0, y: 0.75, z: 0}, // sumados a los finales
            rot: {x: -0.3, y: 0.5, z: 0.15}
        },
        back: {
            start: {
                // base
                posX: !isM ? 0.9 : 0,
                posZ: 0,
                rotY: !isM ? -0.2 : 0,
                
                // bot
                posY: bottlesY,
                rotX: 0,
                rotZ: 0,
            },
            open: {
                // base
                posX: !isM ? 0.1 : 0,
                posZ: !isM ? 2.75 : 3.25,
                rotY: !isM ? Math.PI : Math.PI - 0.1,

                // bot
                posY: 1.9,
                rotX: !isM ? -0.15 : -0.1,
                rotZ: 0.1,
            }
        }
    }
}

if(isM) {
    values.home.camera.pos.z = 6.6
    values.home.camera.target.y = 1.8

    values.home.bottles[0].x = -1.9
    values.home.bottles[2].x = 1.9

    //

    values.interna.camera.pos.z = 5.3
    values.interna.camera.target.y = 1.65
}