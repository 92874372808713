import * as THREE from "three";

import Stats from "three/examples/jsm/libs/stats.module"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module"
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader"
// import { HDRCubeTextureLoader } from "three/examples/jsm/loaders/HDRCubeTextureLoader"

import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";

import {
    EffectComposer, RenderPass, EffectPass,
    BlendFunction,
    ToneMappingEffect, ToneMappingMode,
    NoiseEffect,
    DepthOfFieldEffect, 
    BloomEffect, KernelSize,
    // SMAAImageLoader, SMAAEffect, SMAAPreset, EdgeDetectionMode,
} from "postprocessing"

import { gsap } from 'gsap'
// import { ScrollTrigger } from "gsap/all";

//

import { values } from '../config/values'
import * as mat from "../config/materials"


// ---------------------------------------------------------------------------

const isM = (function isMobile() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}())

const devRot = document.querySelector('#deviceRotated')
isM ? devRot.classList.add('mobile') : devRot.remove()

const size = {
    w: 0, h: 0, halfW: 0, halfH: 0
}


// INIT ----------------------------------------------------------------------

let sigla = 'P'
let clock, camera, scene, renderer, composer;

const GLTF_loader = new GLTFLoader()
const Env_loader = new RGBELoader()
//const urlFiles = location.host == 'localhost:3030' ? '../' : ( location.host == 'localhost:3000' ? '/3D/dist/' : `${drupalSettings.path.themeUrl}/experience/public/3D/dist/`)
const urlFiles = '../'

const contenedorHtml = document.querySelector('#content-3D')
const canvas_home = document.querySelector('#canvas-3D')


// ---------------------------------------------------------------------------

const init = () => {
    
    console.log(`[${sigla}] Scene init`)

    function getRealSize() {
        size.w = window.innerWidth
        size.h = window.innerHeight
        size.halfW = size.w / 2
        size.halfH = size.h / 2
    }
    getRealSize()

    // ---------------------------------------------------------------------------

    clock = new THREE.Clock()

    scene = new THREE.Scene()

    camera = new THREE.PerspectiveCamera( 45, size.w/size.h, 0.1, 150 )
    camera.position.copy( values.home.camera.pos )
    camera.lookAt( new THREE.Vector3().copy( values.home.camera.target ) )

    renderer = new THREE.WebGLRenderer({
        canvas: canvas_home,
        // antialias: true,
        // ----Postprocessing
        powerPreference: "high-performance",
        antialias: false,
        stencil: false,
	    depth: false,
        alpha: true,
    })
    renderer.setPixelRatio( Math.min(window.devicePixelRatio, 2) )
    renderer.setSize( size.w, size.h )
    renderer.physicallyCorrectLights = true
    renderer.outputEncoding = THREE.sRGBEncoding
    renderer.setClearColor(0x00ff00, 0)


    // Extras -----
    // scene.add( new THREE.AxesHelper( 1 ) );

    // let stats = new Stats()
    // stats.dom.style.cssText = 'top: 40%; position: fixed; z-index: 999999'
    // document.body.appendChild( stats.dom )

    let controls = false

    if(controls) {
        let orbit = new OrbitControls( camera, renderer.domElement )
        orbit.target.copy( values.home.camera.target )
        orbit.update()
    } else {
        setInterval( updateCamera, 1000/60 )
    }


    //
    // Titles ----------------------------------------------------------------

    const namesBaseMat = new THREE.MeshStandardMaterial({
        color: 0xffffff,
        metalness: 0.85,
        transparent: true,
        opacity: 0,
        envMapIntensity: 0.9,
        depthWrite: false
    })
    const namesGeo = {}

    new FontLoader().load(`${urlFiles}/modules/custom/co_bavaria_arcade_150/3D/ec_clubpremium/nuestras_cervezas/dist/assets/fonts/Blair.json`,
        (f) => {
            // console.log( Object.keys(values.home.iPos) ) // .find(key => values.home.iPos[key] === 1)

            for(let i = 0; i < Object.keys(values.home.iPos).length; i++) {
                const ref = Object.keys(values.home.iPos)[i]
                // const pos = values.home.iPos[ref]

                const nameRefGeo = new TextGeometry(values.names[ref], {
                    font: f,
                    size: !isM ? 0.4 : 0.25,
                    height: !isM ? 0.075 : 0,
                    curveSegments: 12,
                    // bevelEnabled: true, bevelThickness: 0.015, bevelSize: 0.015,
                })
                nameRefGeo.computeBoundingBox()
                let xMid = - 0.5 * ( nameRefGeo.boundingBox.max.x - nameRefGeo.boundingBox.min.x )
                let yMid = - 0.5 * ( nameRefGeo.boundingBox.max.y - nameRefGeo.boundingBox.min.y )
                nameRefGeo.translate( xMid, yMid, 0 )

                const refMat = namesBaseMat.clone()

                const nameRef = new THREE.Mesh( nameRefGeo, refMat )
                nameRef.name = `Name_${ref}`
                nameRef.position.set(
                    0, // values.home.bottles[pos].x,
                    values.home.namesY,
                    -1.5
                )
                // 
                // if(isM) nameRef.visible = false

                namesGeo[ref] = nameRef
                scene.add(nameRef)
            }
        })

    
    //
    // Load control ----------------------------------------------------------------

    const loadedElements = { }

    let loadPercent = 0
    let loadFinish = false
    function realLoader() {

        let loaded = 0
        Object.keys(loadedElements).forEach( (key) => { loaded += loadedElements[key].loaded })
        let total = 0
        // Object.keys(loadedElements).forEach( (key) => { total += loadedElements[key].total })
        total = 395807 + 131908 + 22356

        //

        let elements = Math.round( (loaded / total) * 100)
        if( isNaN(elements) || total < 1024) elements = 0
        // loadPercent = (mat.texturesData.size.percent + elements) / 2
        loadPercent = elements

        document.querySelector('.loadedValue').innerHTML = `${loadPercent.toFixed(0)}%`
        // document.querySelector('.loadedLine').style.width = `${loadPercent.toFixed(0)}%`

        if(loadPercent === 100 && mat.texturesData.managerState == 'Ready') {

            loadFinish = true
            console.log(`[${sigla}] All ready at ${(clock.getElapsedTime()).toFixed(2)}s \r\n      ${( (total + mat.texturesData.size.total) /1024/1024).toFixed(2)}Mb`)

            gsap.to('.Loader', {
                opacity: 0,
                ease: 'power1.inOut',
                duration: 1,
                delay: 1,
                onStart() {
                    entrada()
                },
                onComplete() {
                    this.targets()[0].remove()
                }
            })
        }
    }

        //

    //
    // Camera control - Desktop ----------------------------------------------------------
    
    function lerp (start, end, amt){
        return (1-amt)*start+amt*end
    }
    // const camera_euler = new THREE.Euler( 0, 0, 0, 'YXZ' )
    function updateCamera(e) {

        if( !mouseMoved || !entradaFinish ) return
        
        const amt = 0.015
        
        // camera_euler.setFromQuaternion( camera.quaternion )
        // camera_euler.y = lerp(camera_euler.y, mousePosition.x * -0.2, amt)
        // camera_euler.x = lerp(camera_euler.x, mousePosition.y * 0.075, amt)
        // camera_euler.z = lerp(camera_euler.z, mousePosition.x * 0.25, amt)
        // camera.quaternion.setFromEuler( camera_euler )

        camera.position.x = lerp(camera.position.x, mousePosition.x, amt)
        camera.position.z = lerp(camera.position.z, (values.home.camera.pos.z - (Math.abs(mousePosition.x) * 0.5)), amt)
        camera.position.y = lerp(camera.position.y, (values.home.camera.pos.y + mousePosition.y), amt)

        values.home.camera.target.x = lerp( values.home.camera.target.x, (mousePosition.x * 0.5), amt)

        camera.lookAt( new THREE.Vector3().copy( values.home.camera.target ) )
    }


    // Scene material
        // Shader interpolation texture source
        // https://codepen.io/frost084/pen/NZdwZO

    const sceneTextures = {
        base: mat.tBase,
        clasica: mat.tClasica,
        platino: mat.tPlatino,
        doble: mat.tDoble,
    }
    
    const shaderSceneMaterial = mat.scene_doble_mat

    const shaderSceneMaterialData = {
        dispFactor: { value: 0 },
        disp: { value: mat.tDisp },

        initial: { value: 1 },
        final: { value: 0 },
        clasica: { value: mat.tClasica },
        platino: { value: mat.tPlatino },
        doble: { value: mat.tDoble }
    }

    shaderSceneMaterial.onBeforeCompile = shader => {
        shader.uniforms.dispFactor = shaderSceneMaterialData.dispFactor
        shader.uniforms.disp = shaderSceneMaterialData.disp

        shader.uniforms.tInitial = shaderSceneMaterialData.initial
        shader.uniforms.tFinal = shaderSceneMaterialData.final
        shader.uniforms.tClasica = shaderSceneMaterialData.clasica
        shader.uniforms.tPlatino = shaderSceneMaterialData.platino
        shader.uniforms.tDoble = shaderSceneMaterialData.doble

        shader.fragmentShader =
            `
            uniform float dispFactor;
            uniform sampler2D disp;

            uniform int tInitial;
            uniform int tFinal;
            uniform sampler2D tClasica;
            uniform sampler2D tPlatino;
            uniform sampler2D tDoble;
            ` + shader.fragmentShader

        shader.fragmentShader = shader.fragmentShader.replace('#include <map_fragment>',
            `            
            vec2 uv = vUv;

            vec4 disp = texture2D(disp, uv);

            float r = dispFactor * (1.0 + 0.05 * 2.0) - 0.05;
            float mixit = clamp((disp.r - r) * (1.0 / 0.05), 0.0, 1.0);

            vec4 _tInitial = texture2D(tDoble, uv);
            if(tInitial == ${values.home.iPos.clasica}) _tInitial = texture2D(tClasica, uv);
            if(tInitial == ${values.home.iPos.doble}) _tInitial = texture2D(tDoble, uv);
            if(tInitial == ${values.home.iPos.platino}) _tInitial = texture2D(tPlatino, uv);

            vec4 _tFinal;
            if(tFinal == ${values.home.iPos.clasica}) _tFinal = texture2D(tClasica, uv);
            if(tFinal == ${values.home.iPos.doble}) _tFinal = texture2D(tDoble, uv);
            if(tFinal == ${values.home.iPos.platino}) _tFinal = texture2D(tPlatino, uv);

            diffuseColor *= mix(_tFinal, _tInitial, mixit);
            `)
    }



    //
    // EnvMap ------------------------------------------------------------------

    const envObj = "studio_country_hall_1k"
    Env_loader
        .setDataType(THREE.UnsignedByteType)
        .load(`${urlFiles}/modules/custom/co_bavaria_arcade_150/3D/ec_clubpremium/nuestras_cervezas/dist/assets/glb/envMap/${envObj}.hdr`,
            (env) => {
                const pmremGenerator = new THREE.PMREMGenerator(renderer)
                pmremGenerator.compileEquirectangularShader()
                const HDRImap = pmremGenerator.fromEquirectangular(env).texture

                scene.environment = HDRImap
                // scene.background = HDRImap

                // HDRImap.dispose()
                // pmremGenerator.dispose()
            },
            (xhr) => {
                loadedElements.env = { loaded: xhr.loaded, total: xhr.total}

                // if (xhr.loaded === xhr.total) {
                //     console.log(`[${sigla}] "${envObj}" - ${(xhr.total / 1024 / 1024).toFixed(2)}Mb`)
                // }
            },
            (error) => {
                console.error(`[${sigla}] "${envObj}" Error loading`)
                // console.warn( error )
            }
        )


    // GLTF ------------------------------------------------------------------
    // gltfpack -c -vt 14 -vn 10 -kn -i ./dist/assets/glb/01.glb -o ./dist/assets/glb/01c.glb

    const shadow_geo = new THREE.PlaneBufferGeometry(1, 1)
    const shadow_mesh = new THREE.Mesh(shadow_geo, mat.shadow_mat);
    shadow_mesh.name = 'Shadooow'
    shadow_mesh.position.y = 0.01 // so we're above the ground slightly
    shadow_mesh.rotation.x = - Math.PI / 2;

    let bottle_models = { base: {}, bottles: {} }
    const cloneBottle = (ref, obj) => {

        let cont = bottle_models.base[ref] = new THREE.Object3D()
        cont.name = `Base_${ref}`

        let elem = bottle_models.bottles[ref] = obj
        elem.name = `Botella_${ref}_grp`
        elem.userData.name = ref
        cont.add(elem)
        
        scene.add(cont)

        // Position set up
        const pos = values.home.iPos[ref]

        cont.position.set( values.home.bottles[pos].x, 0, values.home.bottles[pos].z )
        elem.position.y = values.home.bottles[pos].y + values.home.entrada.pos.y

        elem.rotation.x = values.home.entrada.rot.x
        elem.rotation.y = values.home.entrada.rot.y
        if(pos == 0) {
            elem.rotation.z = values.home.entrada.rot.z
        }
        if(pos == 2) {
            elem.rotation.z = -values.home.entrada.rot.z
        }
        
        cont.add( shadow_mesh.clone() )

            //

        // Materials
        obj.traverse( (elem) => {
            if( elem.isMesh == true ) {

                switch (elem.name) {
                    case 'Botella_01':
                        elem.material = mat.botella_mat
                        elem.renderOrder = 1
                        break;
                    case 'Etiqueta_01':
                    case 'Etiqueta_02':
                        elem.material = mat[`${ref}_front_mat`]
                        elem.renderOrder = 2
                        break;
                    case 'EtiquetaBack':
                        elem.material = mat[`${ref}_back_mat`]
                        break;
                    case 'Tapa':
                        elem.material = mat[`${ref}_tapa_mat`]
                        elem.renderOrder = 2
                        break;
                
                    default:
                        console.log(`Falta- ${ref}, ${elem.name}`)
                        break;
                }
            }
        })

        raycastElems.push(elem)
    }

    const glb01 = 'Botella.glb'
    GLTF_loader
        // .setMeshoptDecoder(MeshoptDecoder)
        .load( `${urlFiles}/modules/custom/co_bavaria_arcade_150/3D/ec_clubpremium/nuestras_cervezas/dist/assets/glb/${glb01}`,
            (gltf) => {
                const model = gltf.scene.children[0]

                cloneBottle('clasica', model.clone() )
                cloneBottle('platino', model.clone() )
                cloneBottle('doble', model.clone() )

                bottle_models.bottles['clasica'].userData.cState = 'Prev'
                bottle_models.bottles['doble'].userData.cState = 'Active'
                bottle_models.bottles['platino'].userData.cState = 'Next'
            },
            (xhr) => {
                loadedElements.glb_botella = { loaded: xhr.loaded, total: xhr.total }

                // if (xhr.loaded === xhr.total) {
                //     console.log(`[${sigla}] "${glb01}" - ${(xhr.total / 1024 / 1024).toFixed(2)}Mb`)
                // }
            },
            (error) => {
                console.warn(`[${sigla}] "${glb01}" Error loading`)
                // console.warn( error )
            }
        )

    const glb02 = 'Scenography.glb'
    GLTF_loader
        // .setMeshoptDecoder(MeshoptDecoder)
        .load( `${urlFiles}/modules/custom/co_bavaria_arcade_150/3D/ec_clubpremium/nuestras_cervezas/dist/assets/glb/${glb02}`,
            (gltf) => {
                const model = gltf.scene.children[0]

                model.position.z = -5
                model.rotation.y = Math.PI
                let sf = 8
                model.scale.set(sf*0.65, sf, sf)

                model.material = shaderSceneMaterial //mat.scene_doble_mat

                //
                scene.add( model )

            },
            (xhr) => {
                loadedElements.glb_scenography = { loaded: xhr.loaded, total: xhr.total }

                // if (xhr.loaded === xhr.total) {
                //     console.log(`[${sigla}] "${glb02}" - ${(xhr.total / 1024 / 1024).toFixed(2)}Mb`)
                // }
            },
            (error) => {
                console.warn(`[${sigla}] "${glb02}" Error loading`)
                // console.warn( error )
            }
        )

        //

    let entradaFinish = false
    function entrada() {

        let bottles = Object.values(bottle_models.bottles)

        const t = gsap.timeline({
            defaults: {
                ease: 'power2.out',
                duration: 3.5
            },
            onComplete() {
                entradaFinish = true
                makeActive('doble')
                addListeners()
            }
        })

        bottles.forEach( (a) => {
            const ref = a.userData.name
            const pos = values.home.iPos[ref]  

            t.to(a.position, {
                y: values.home.bottles[pos].y,
                ease: 'back.out(1.4)'
            }, 0)
            t.to(a.rotation, {
                x: 0,
                y: 0,
                z: 0,
            }, 0)
        })

        t.to('.generalUI', {
            opacity: 1,
            ease: 'power2.inOut',
            duration: 2.5
        }, 2)
    }

    const verMasElem = document.querySelector('.desktop-pointer-more')
    const verMasMobileElem = document.querySelector('.mobile-pointer-more')

    const prevElem = document.querySelector('.c-arrow[data-action=prev]')
    const nextElem = document.querySelector('.c-arrow[data-action=next]')

    /**
     * @description Depende del raycast
     */
    function handleVerMasButton(e) {

        if( raycastData.state == false ) {
            verMasElem.setAttribute('data-target', 'none')
            verMasElem.classList.remove('active')

            prevElem.removeAttribute('style')
            nextElem.removeAttribute('style')
            return
        }

        //

        if( raycastData.action == 'Active' ) {
            let target = raycastData.obj.userData.name
            verMasElem.setAttribute('data-target', target)
            verMasElem.classList.add('active')
            verMasElem.style.left = `${e.clientX}px`
            verMasElem.style.top = `${e.clientY}px`
        }

        if( raycastData.action == 'Prev' ) {
            prevElem.style.left = `${e.clientX}px`
            prevElem.style.top = `${e.clientY}px`
        }

        if( raycastData.action == 'Next' ) {
            nextElem.style.left = `${e.clientX}px`
            nextElem.style.top = `${e.clientY}px`
            nextElem.style.transform = 'translate(-50%, -50%) rotate(180deg)'
        }

    }
    
    function goToRef(e) {
        if(!isM && !raycastData.state) return

        let target = !isM ? raycastData.obj.userData.name : e.target.getAttribute('data-target')
        let bottles = Object.values(bottle_models.bottles)

        const t = gsap.timeline({
            defaults: { },
            onStart() {
                entradaFinish = false
                makeInactive(true)
            },
            onComplete() {
                if(target == 'doble') target = 'doble-malta'
                target = 'club-' + target
                window.location = `/nuestras-cervezas/${target}`
            }
        })

        bottles.forEach( (a) => {
            let delay = a.userData.name == target ? 0 : 0.65
            // if( values.home.iPos[target] == 0 || values.home.iPos[target] == 2) {
            //     const middleBottle = Object.keys(values.home.iPos).find(key => values.home.iPos[key] === 1);
            //     if(a.userData.name == middleBottle) delay = 0.25
            // }

            t.to(a.position, {
                y: '+=0.75',
                duration: 1.5,
                ease: 'power1.in',
                delay: delay
            }, 0)
        })

        t.to('.outModal', {
            opacity: 1,
            duration: 1,
            onStart() {
                this.targets()[0].style.display = 'block'
            }
        }, 0.75)

    }
    verMasElem.addEventListener('pointerup', goToRef, false)

        //

    function prevActive() {
        actualPos ++
        posActive(actualPos)
    }
    function nextActive() {
        actualPos --
        posActive(actualPos)
    }

    function addListeners() {
        document.querySelector('.c-arrow[data-action=prev]').addEventListener( 'pointerup', prevActive, false)
        document.querySelector('.c-arrow[data-action=next]').addEventListener( 'pointerup', nextActive, false)

        verMasMobileElem.addEventListener('pointerup', goToRef, false)
    }
    function removeListeners() {
        document.querySelector('.c-arrow[data-action=prev]').removeEventListener( 'pointerup', prevActive, false)
        document.querySelector('.c-arrow[data-action=next]').removeEventListener( 'pointerup', nextActive, false)

        verMasMobileElem.removeEventListener('pointerup', goToRef, false)
    }

        //

    let actualPos = 144
    const globalDuration = 1
    const totalPos = Object.keys(values.home.bottles).length

    const carrouselControl = {
        clasica: {next: 'doble', prev: 'platino'},
        platino: {next: 'clasica', prev: 'doble'},
        doble: {next: 'platino', prev: 'clasica'}
    }
    
    function posActive(pos) {

        removeListeners()

        //

        let currentActive
        switch (pos % totalPos) {
            case 0: //clasica
                currentActive = 'doble'
                break
            case 1: //doble
                currentActive = 'clasica'
                break
            case 2: //platino
                currentActive = 'platino'
                break
            default:
                console.error(`[${sigla}] posActive() no pos`)
                break;
        }

        
        bottle_models.bottles[ carrouselControl[currentActive].prev ].userData.cState = 'Prev'
        bottle_models.bottles[ currentActive ].userData.cState = 'Active'
        bottle_models.bottles[ carrouselControl[currentActive].next ].userData.cState = 'Next'

            //

        const t = gsap.timeline({
            defaults: {
                ease: 'power1.inOut',
                duration: globalDuration
            },
            onStart() {
                makeInactive()
            },
            onUpdate() {
                verMasElem.classList.remove('active')
            },
            onComplete() {
                verMasElem.classList.remove('active')
                verMasElem.removeAttribute('style')
                /*prevElem.removeAttribute('style')
                nextElem.removeAttribute('style')*/

                makeActive(currentActive)
                addListeners()
            }
        })

        t.to(bottle_models.base.clasica.position, {
            x: values.home.bottles[ pos % totalPos ].x,
            z: values.home.bottles[ pos % totalPos ].z
        }, 0)
        t.to(bottle_models.base.doble.position, {
            x: values.home.bottles[ (pos + 1) % totalPos ].x,
            z: values.home.bottles[ (pos + 1) % totalPos ].z
        }, 0)
        t.to(bottle_models.base.platino.position, {
            x: values.home.bottles[ (pos + 2) % totalPos ].x,
            z: values.home.bottles[ (pos + 2) % totalPos ].z
        }, 0)
    }

    const activeControl = {
        state: 'inactive',
        last: null,
        timeline: null
    }
    function makeActive(target) {
        activeControl.state = 'active'
        activeControl.last = target

        const object = bottle_models.bottles[target]
        const name = namesGeo[target]

        // raycastElems = [object]

        document.querySelector('.mobile-pointer-more').setAttribute('data-target', target)
        // document.querySelector('.controls-container').classList.remove('int')

            //

        let t = activeControl.timeline = gsap.timeline({
            defaults: {
                duration: globalDuration,
                ease: 'power1.out'
            }
        })

        // Shader scene
        const pos = values.home.iPos[target]
        shaderSceneMaterialData.final.value = pos

        t.to(shaderSceneMaterialData.dispFactor, {
            value: 1,
            onComplete() {
                shaderSceneMaterialData.initial.value = pos
                shaderSceneMaterialData.dispFactor.value = 0
            }
        }, 0)

        // Objects
        t.to(object.position, {
            y: values.home.bottles[1].y + 0.175,
        }, 0)
        t.to(object.rotation, {
            x: -0.12,
            y: -0.075, //-0.2,
            z: -0.1
        }, 0)
        t.to(name.material, {
            opacity: 1,
            duration: globalDuration * 1.2,
        }, 0)
        t.to(name.position, {
            y: '+=0.25',
            duration: globalDuration * 1.2,
        }, 0)

        // Loop
        t.to(object.position, {
            y: values.home.bottles[1].y + 0.1,
            duration: 1.5,
            ease: 'power1.inOut',
            repeat: -1,
            yoyo: true,
        }, 0.75)
    }
    function makeInactive(out) {
        activeControl.state = 'inactive'

        const object = bottle_models.bottles[activeControl.last]
        const name = namesGeo[activeControl.last]

        // raycastElems = []

        activeControl.timeline.pause()
        activeControl.timeline = null

        if(out) return

            //

        // document.querySelector('.controls-container').classList.add('int')

        const t = gsap.timeline({
            defaults: {
                duration: globalDuration * 0.75
            }
        })

        // t.to(shaderSceneMaterialData.dispFactor, {
        //     value: 0,
        //     duration: globalDuration * 0.5
        // }, globalDuration * 0.4)

        t.to(object.position, {
            y: values.home.bottles[1].y,
        }, 0)
        t.to(object.rotation, {
            x: 0,
            y: 0,
            z: 0
        }, 0)

        t.to(name.material, {
            opacity: 0,
        }, 0)
        t.to(name.position, {
            y: values.home.namesY,
        }, 0)

        activeControl.last = null
    }


    
    // Raycast ------------------------------------------------------------
    const raycast = new THREE.Raycaster()
    let raycastData = {
        state: false,
        obj: null,
        action: null
    }
    let raycastElems = []
    
    function ray(e) {
        // e.preventDefault()

        raycast.setFromCamera(mousePosition, camera)

        let intersects = raycast.intersectObjects(raycastElems, true)

        if( intersects.length > 0 ) {
            const obj = intersects[0].object.parent
            
            raycastData.state = true
            raycastData.obj = obj
            raycastData.action = obj.userData.cState

            contenedorHtml.style.cursor = 'pointer'
        } else {
            raycastData.state = false
            raycastData.obj = null
            raycastData.action = null

            contenedorHtml.style.cursor = 'default'
        }
    }


    //
    // Desktop------------------------------------------------------------------

    let mousePosition = {x: size.halfW, y: 0}
    let mouseMoved = false

    function onPointerDown(e) {
        switch (e.pointerType) {
            case 'mouse':
            case 'pen':
                //
                break;
            case 'touch':
                let rect = renderer.domElement.getBoundingClientRect()
                mousePosition.x = ( ( e.clientX - rect.left ) / rect.width ) * 2 - 1
                mousePosition.y = - ( ( e.clientY - rect.top ) / rect.height ) * 2 + 1

                if(entradaFinish) ray()

                break;
        }
    }

    function onPointerMovement(e) {
        switch (e.pointerType) {
            case 'mouse':
            case 'pen':
                let rect = renderer.domElement.getBoundingClientRect()
                mousePosition.x = ( ( e.clientX - rect.left ) / rect.width ) * 2 - 1
                mousePosition.y = - ( ( e.clientY - rect.top ) / rect.height ) * 2 + 1

                // To camera movement
                mouseMoved = true

                if(entradaFinish) {
                    ray()
                    handleVerMasButton(e)
                    // if(raycastData.state && activeControl.state == 'inactive') makeActive()
                    // if(!raycastData.state && activeControl.state == 'active') makeInactive()
                }

                break;
        }
    }

    contenedorHtml.addEventListener( 'pointerdown', onPointerDown, false)
    contenedorHtml.addEventListener( 'pointermove', onPointerMovement, false )


    // Render ------------------------------------------------------------------

    const renderPass = new RenderPass(scene, camera)

    const noiseEffect = new NoiseEffect({ premultiply: true })
    noiseEffect.blendMode.opacity.value = 0.5

    const toneMappingEffect = new ToneMappingEffect({
        mode: ToneMappingMode.ACES_FILMIC,
        resolution: 128
    })

    const bloomEffect = new BloomEffect({
        intensity: 0.15,
        luminanceThreshold: 0.25,
        luminanceSmoothing: 0.7,
        kernelSize: KernelSize.HUGE
    })

    const dofEffect = new DepthOfFieldEffect( camera, {
        focusDistance: 0.15,
        focalLength: 0.45,
        bokehScale: 4
    })

    let ePass
    if(!isM) {
        ePass = new EffectPass( 
            camera,
            bloomEffect,
            toneMappingEffect,
        )
    } else {
        ePass = new EffectPass( 
            camera,
            toneMappingEffect,
        )
    }

    composer = new EffectComposer(renderer, {
        frameBufferType: THREE.HalfFloatType
    })
    composer.addPass( renderPass )
    composer.addPass( ePass )

    // ---------------------------------------------------------------------------

    function onWindowResize() {
        if(!isM) getRealSize()
        
        camera.aspect = size.w / size.h
        camera.updateProjectionMatrix()
        renderer.setSize( size.w, size.h )
        composer.setSize( size.w, size.h )
    }
    window.addEventListener( 'resize', onWindowResize, false)


    // ---------------------------------------------------------------------------

    function animate() {
        requestAnimationFrame( animate )

        // stats.update()

        if(loadFinish == false) realLoader()

        //

        composer.render( clock.getDelta() )
        // renderer.render( scene, camera )
    }
    animate()

}

export { init }