import * as THREE from "three";
// import * as THREE from '../js/three.moduleR124';


const isM = (function isMobile() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}())

const manager = new THREE.LoadingManager()
const TXloader = new THREE.TextureLoader(manager)


// ---------------------------------------------------------------------------

export let texturesData = {
    loaded: 0,
    total: 0,
    managerState: 'Pending',
    size: {
        raw: [0],
        total: 0,
        percent: 0
    },
}

manager.onStart = (url: any, itemsLoaded: number, itemsTotal: number) => {
    // console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
    texturesData.managerState = 'Loading'
}
manager.onProgress = ( url: any, itemsLoaded: number, itemsTotal: number ) => {
    // console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
    texturesData.loaded = itemsLoaded
    texturesData.total = itemsTotal
};
manager.onLoad = ( ) => {
    // if( imagesLoaded === imagesTotal && imagesLoaded > 0) {
        texturesData.managerState = 'Ready'
        // console.log(`Textures: ${texturesData.total}, ${(texturesData.size.total / 1024 / 1024).toFixed(2)}Mb`)
    // }
};

function imagesLoader(tx_name: string, encoding='sRGB') {
    let path = '/modules/custom/co_bavaria_arcade_150/3D/ec_clubpremium/nuestras_cervezas/dist/assets/glb/textures/'
    // if(isM) path = './modules/custom/co_bavaria_arcade_150/3D/ec_clubpremium/nuestras_cervezas/dist/assets/glb/textures/min/'
    const t = TXloader
        .load(`${path}${tx_name}`, 
            (img: any) => {
                // console.log(tx_name)
            }, 
            (xhr: any) => {
                // if(!texturesData.size.raw.includes( xhr.total )) {
                //     texturesData.size.raw.push( xhr.total )
                // }
            },
            (error: any) => { console.error(`[ERROR] Image '${tx_name}'`) }
        )
    if (encoding === 'sRGB') t.encoding = THREE.sRGBEncoding
    if (encoding === 'Linear') t.encoding = THREE.LinearEncoding
    t.flipY = false
    // t.name = url
    t.dispose()

    return t
}

// ---------------------------------------------------------------------------


        // Obsolote debido a que el TextureLoader
        // ya no recibe valores de carga parcial y total
        // esperar fix

    // function loadControl() {
    //     // texturesData.size.total = 0
    //     // for (var y = 0; y < texturesData.size.raw.length; y++){
    //     //     texturesData.size.total += texturesData.size.raw[y]
    //     // }

    //     texturesData.size.percent = Math.round( (texturesData.loaded / texturesData.total) * 100)
    //     if( isNaN(texturesData.size.percent) ) texturesData.size.percent = 0

    //     if(texturesData.size.percent === 100) {
    //         texturesData.loadFinish = true
    //     }
    // }
    // let r
    // function anim() {
    //     r = requestAnimationFrame( anim )
    //     if(texturesData.loadFinish == false) {
    //         loadControl()
    //     } else {
    //         window.cancelAnimationFrame( r )
    //     }
    // }
    // anim()


// ----------------------------------------------------------------------------
// Materials ------------------------------------------------------------------

export const shadow_mat = new THREE.MeshBasicMaterial({
    map: imagesLoader('shadow_min.png'),
    transparent: true,    // so we can see the ground
    depthWrite: false,    // so we don't have to sort
    opacity: 0.65,
    side: THREE.DoubleSide
})
// shadow_mat.map = imagesLoader('shadow_min.png')

    //

export const botella_mat = new THREE.MeshPhysicalMaterial({
    color: 0x13630e,
    normalMap: imagesLoader('Normal-Botella.png', 'Linear'),
    metalness: 0,
    roughness: 0.12,
    envMapIntensity: 1.25,
    // opacity: 1,
    // transparent: true,
    transmission: 1,
    clearcoat: 0.5,
    clearcoatRoughness: 0.3,
    specularTint: new THREE.Color(0x2e4633),
    thickness: 6,
})

const label_mat = new THREE.MeshStandardMaterial({
    roughness: 0.25,
    metalness: 0.9,
    envMapIntensity: 1,
    // transparent: true,
    alphaTest: 0.99,
    // side: THREE.DoubleSide
})

const tapa_mat = new THREE.MeshStandardMaterial({
    roughness: 0.3,
    metalness: 0.95,
    envMapIntensity: 1,
})

const scenography_mat = new THREE.MeshStandardMaterial({
    roughness: 0.4,
    metalness: 0.85,
    envMapIntensity: 1
})

    //

export const clasica_front_mat =  label_mat.clone()
clasica_front_mat.map = imagesLoader('Clasica_front.jpg')
clasica_front_mat.alphaMap = imagesLoader('Clasica_front_alpha.png', 'Linear')
export const clasica_back_mat =  label_mat.clone()
clasica_back_mat.map = imagesLoader('Clasica_back.jpg')
clasica_back_mat.alphaMap = imagesLoader('Clasica_back_alpha.png', 'Linear')

export const platino_front_mat =  label_mat.clone()
platino_front_mat.map = imagesLoader('Platino_front.jpg')
platino_front_mat.alphaMap = imagesLoader('Platino_front_alpha.png', 'Linear')
export const platino_back_mat =  label_mat.clone()
platino_back_mat.map = imagesLoader('Platino_back.jpg')
platino_back_mat.alphaMap = clasica_back_mat.alphaMap

export const doble_front_mat =  label_mat.clone()
doble_front_mat.map = imagesLoader('Doble_front.jpg')
doble_front_mat.alphaMap = imagesLoader('Doble_front_alpha.png', 'Linear')
export const doble_back_mat =  label_mat.clone()
doble_back_mat.map = imagesLoader('Doble_back.jpg')
doble_back_mat.alphaMap = clasica_back_mat.alphaMap

    //

export const clasica_tapa_mat = tapa_mat.clone()
clasica_tapa_mat.map = imagesLoader('Clasica_tapa.png')

export const platino_tapa_mat = tapa_mat.clone()
platino_tapa_mat.map = imagesLoader('Platino_tapa.png')

export const doble_tapa_mat = tapa_mat.clone()
doble_tapa_mat.map = imagesLoader('Doble_tapa.png')

    // Internas

export const scene_clasica_mat = scenography_mat.clone()
scene_clasica_mat.map = imagesLoader('Scene_clasica.jpg')

export const scene_platino_mat = scenography_mat.clone()
scene_platino_mat.map = imagesLoader('Scene_platino.jpg')

export const scene_doble_mat = scenography_mat.clone()
scene_doble_mat.map = imagesLoader('Scene_base.jpg')

    // Shader

export const tDisp = imagesLoader('disp.jpg')
export const tBase = imagesLoader('Scene_base.jpg')
export const tClasica = imagesLoader('Scene_clasica.jpg')
export const tPlatino = imagesLoader('Scene_platino.jpg')
export const tDoble = imagesLoader('Scene_doble.jpg')