import * as React from "react"

function App() {
    return (
    <>

        {/* Page HTML */}

        <section id='content-3D' data-state='loading'>

            <div id="deviceRotated">
                <span>
                    <img src="/modules/custom/co_bavaria_arcade_150/3D/ec_clubpremium/nuestras_cervezas/dist/assets/images/deviceRotated.svg" />
                    Rota tu dispositivo.
                </span>
            </div>

            <div className='Loader'>
                <span className='loader-circle'></span>
                <span className='loadedValue'>0%</span>
            </div>

            <div className='outModal'>
            </div>

            <canvas id='canvas-3D'></canvas>

            <h1 className='home-title'>Cervezas club premium</h1>

            <div className='generalUI'>

                <div className='desktop-help'>Haz clic en la botella central para ver más y clic en las flechas para cambiar.</div>

                <div className='desktop-pointer-more'></div>

                    <div className='c-arrow' data-action='prev'></div>
                    <div className='c-arrow' data-action='next'></div>
                    <div className='mobile-pointer-more'>ver más</div>

            </div>

        </section>

    </>
    )
}

export default App
