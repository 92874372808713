import * as React from "react"
import * as ReactDom from "react-dom"

import View from "../views/home"

//
  
ReactDom.render(<View /> , document.getElementById('app'))

export const page = async () => {
	require('../js/home.js').init()
}