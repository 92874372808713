export const routes = [
    {
        name: 'index',
        path: ['/nuestras-cervezas', '/'],
        page: 'home'
    },
    {
        name: 'clasica',
        path: ['/nuestras-cervezas/club-clasica'],
        page: 'referencias'
    },
    {
        name: 'platino',
        path: ['/nuestras-cervezas/club-platino'],
        page: 'referencias'
    },
    {
        name: 'doble',
        path: ['/nuestras-cervezas/club-doble-malta'],
        page: 'referencias'
    }
]